import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Link from "gatsby-link";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import {GatsbyImage} from "gatsby-plugin-image";
import {Parallax} from "react-scroll-parallax";
import Initiative from "../components/Initiative";

export const query = graphql`
    {
        allSanityInitiativen {
            edges {
                node {
                    fb
                    insta
                    linkedin
                    website
                    title
                    _rawBody
                }
            }
        }
            allSanityInitiativenoverview {
    edges {
      node {
          allinitiativen
      image{ asset{ gatsbyImageData}}
      credits
        _rawIntrotext
        _rawZusatztext
        energie {
          fb
          insta
          linkedin
          website
          title
          _rawBody
        }
        gemeinschaft {
          fb
          insta
          linkedin
          website
          title
          _rawBody
        }
        europa {
          fb
          insta
          linkedin
          website
          title
          _rawBody
        }
        wildnis {
          fb
          insta
          linkedin
          website
          title
          _rawBody
        }
        boden {
          fb
          insta
          linkedin
          website
          title
          _rawBody
        }
      }
    }
  }
        }
    `;

const Coaching = ({data}) => {

    const initiativen = data.allSanityInitiativenoverview.edges[0].node;
    const initiativenPool = data.allSanityInitiativen.edges;

    const allInitiativen = [].concat(
        initiativen.energie,
        initiativen.wildnis,
        initiativen.gemeinschaft,
        initiativen.boden,
        initiativen.europa
    );

    const sortedInitiativen = allInitiativen.sort((a, b) => a.title.localeCompare(b.title));
    const sortedInitiativenPool = initiativenPool.sort((a, b) => a.node.title.localeCompare(b.node.title));


    return (
        <Layout newsletter={false} page={"initiativen"} backBtn={true}>
            <SEO title={"Initiativen"}/>
            <div className="initiativen__outer">
                <div className="initiativen__inner">
                    <div className="initiativen__landing__outer">
                        <div className="initiativen__landing__inner">
                            <div className="coaching__image mobile">
                                <GatsbyImage image={initiativen.image.asset.gatsbyImageData}></GatsbyImage>
                                    <p className="credits__container small">FOTO: {initiativen.credits}</p>
                            </div>
                            <Parallax translateY={[-20, 80]} className="left initiativen__title desktopblock">
                                <h2 className={"larger"}>Initiativen</h2>
                                <div className="programm__zusatztext">
                                    <MyPortableText value={initiativen._rawZusatztext}></MyPortableText>
                                </div>
                            </Parallax>
                            <div className="left initiativen__title mobile">
                                <h2 className={"larger"}>Initiativen</h2>
                                <div className="programm__zusatztext">
                                    <MyPortableText value={initiativen._rawZusatztext}></MyPortableText>
                                </div>
                            </div>
                            <div className="right initiativen__introtext">
                                <div className="coaching__image desktopblock">
                                    <GatsbyImage image={initiativen.image.asset.gatsbyImageData}></GatsbyImage>
                                    <p className="credits__container small landing">FOTO: {initiativen.credits}</p>
                                </div>
                                <h3 className={"paddingright"}>
                                    <MyPortableText value={initiativen._rawIntrotext}></MyPortableText>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="initiativen__einzel__outer">
                        <div className="initiativen__einzel__inner">
                            <div className="initiativen__category">
                                {initiativen.allinitiativen ?
                                    <div className="initiativeneinzel__outer">
                                        {sortedInitiativenPool.map((el, index) => (
                                            <Initiative key={index} el={el.node} />
                                        ))}
                                    </div>
                                    :
                                    <div className="initiativeneinzel__outer">
                                        {sortedInitiativen.map((el, index) => (
                                            <Initiative key={index} el={el} />
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section__outer">
                        <div className="section__inner">
                            <h4 className={"uppercase"}>KONTAKT</h4>
                            <div className="separator"></div>
                            <div className="coll__outer flexspacebetween">
                                <div className="coll3">
                                    <p className={"smallwidth col3"}>
                                        Du hast Fragen zu den Initiativen oder möchtest als Initiative mit dem <span className={"demibold"}>Markt der Zukunft</span> in Kontakt tretten? Dann schreib uns eine E-Mail an <a href="mailto:office@marktderzukunft.at" className="underline">office@marktderzukunft.at</a>.
                                    </p>
                                </div>
                                <div className="coll3 coaching desktop">
                                    <Link to={"/kontakt"} className="btn__inner light icon--small">
                                        Kontakt
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398" style={{transform : "rotate(-90deg"}}>
                                            <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707)">
                                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Coaching;
